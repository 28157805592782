module.exports = [{
      plugin: require('/solution/node_modules/.registry.npmjs.org/gatsby-remark-images/3.1.25_82878940f19774a3cf3db21c1ffc6ad1/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1140},
    },{
      plugin: require('/solution/node_modules/.registry.npmjs.org/gatsby-plugin-google-tagmanager/5.13.1_7fe8e194c5ece6624c05cb4f19ed6d3d/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KQ7V6HK4","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"function() {\n          return {\n            pageType: window.pageType,\n          }\n        }"}},
    },{
      plugin: require('/solution/node_modules/.registry.npmjs.org/gatsby-plugin-google-gtag/5.13.1_7fe8e194c5ece6624c05cb4f19ed6d3d/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-4RE83DTRHZ"],"pluginConfig":{"head":true}},
    },{
      plugin: require('/solution/node_modules/.registry.npmjs.org/gatsby-plugin-google-analytics/2.1.20_7fe8e194c5ece6624c05cb4f19ed6d3d/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-132209813-1"},
    },{
      plugin: require('/solution/ANEX.Website.Osu/CTL/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onClientEntry = () => {
  const CTL = {
    ABN: '38 662 964 730',
  }

  window.CTL = Object.freeze(CTL)
}
